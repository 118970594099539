import React from 'react';

interface IconProps {
  className?: string;
  style?: React.CSSProperties;
}

interface Props {
  data: Array<{
    icon: React.ComponentClass<IconProps> | React.FC<IconProps>;
    title: string;
    image: string;
    caption: string;
  }>;
}

const PartnerFeaturesList: React.FC<Props> = ({ data }) => {
  return (
    <div className="w-full bg-blue-900">
      <div className="container lg:flex lg:flex-row lg:items-center py-16 lg:py-20 px-4 lg:px-0">
        <div className="lg:flex-1 flex flex-col items-center lg:items-start text-center lg:text-left">
          <div className="max-w-lg">
            <div className="text-3xl leading-tight text-teal-400">
              BOCS is a SaaS Application for{' '}
              <b>Monitoring Construction Projects</b>
            </div>
            <div className="pt-4 text-blue-100">
              BOCS serves as a <b>decision support system</b> for project
              managers executing field construction projects. It delivers
              readymade interpretations and not just data and info.
            </div>
          </div>
          <div className="flex flex-row justify-center lg:justify-start flex-wrap">
            {data.map(item => {
              const { title, caption, icon: Icon } = item;
              return (
                <div className="flex pt-8 lg:pt-20 lg:pr-8" key={title}>
                  <div className="w-20 h-20 rounded bg-blue-800 flex justify-center items-center">
                    <Icon />
                  </div>
                  <div className="lg:max-w-xxs h-full flex flex-col justify-between pl-4 text-left">
                    <div className="text-teal-400 font-bold text-lg lg:text-xl">
                      {title}
                    </div>
                    <div className="max-w-xs text-blue-100 text-sm lg:text-base">
                      {caption}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex-none invisible absolute lg:flex-1/3 lg:visible lg:relative">
          <img src={data[0].image} alt={data[0].caption} />
        </div>
      </div>
    </div>
  );
};

export default PartnerFeaturesList;
