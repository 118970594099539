import Govt from '../images/icons/govt.inline.svg';
import Private from '../images/icons/private.inline.svg';
import PMC from '../images/icons/pmc.inline.svg';
import Urban from '../images/icons/urban.inline.svg';

export const TARGET_CUSTOMERS = [
  {
    name: 'Government and PSU',
    desc: 'executing multiple large infrastructural projects',
    icon: Govt,
  },
  {
    name: 'Private Infrastructure Companies',
    desc: 'executing large construction projects',
    icon: Private,
  },
  {
    name: 'Project Management Consultants',
    desc: 'responsible for monitoring and reporting on construction project',
    icon: PMC,
  },
  {
    name: 'Real Estate Developers',
    desc: ' in executing multiple real estate construction projects.',
    icon: Urban,
  },
];
