import React from 'react';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Navbar from '../components/NavBar';
import FeaturesList from '../components/PartnerFeaturesList';
import PartnerLeadGenerationForm from '../components/PartnerLeadGenerationForm';
import { PARTNER_FEATURES, VALUE_ADDITION } from '../data/features';
import { TARGET_CUSTOMERS } from '../data/targetCustomers';

const partnerBg = require('../images/partnerBg.png');
const partnerBgBottom = require('../images/partnerBgBottom.png');

const Partners = () => {
  return (
    <>
      <SEO title="BOCS - Partners" />
      <Layout>
        <Navbar type="partner" />
        <div
          className="relative flex flex-col bg-no-repeat min-h-screen-1/2"
          style={{
            backgroundImage: `url("${partnerBg}")`,
            backgroundSize: 'cover',
          }}
        >
          <div className="container">
            <div className="flex flex-col lg:flex-row lg:justify-center items-center pt-24 pb-8 px-4 lg:px-0 lg:py-40 z-10">
              <div className="flex-1 text-center lg:text-left">
                <div className="max-w-md">
                  <div className="text-teal-200">BOCS ISV Partnership</div>
                  <div className="text-teal-400 text-4xl lg:text-5xl leading-tight">
                    Collaborate with <b>BOCS</b> and give your business a
                    competitive edge
                  </div>
                </div>
              </div>
              <div className="flex-3/4 mt-8 lg:mt-0">
                <PartnerLeadGenerationForm key="partnerLead" />
              </div>
            </div>
          </div>
        </div>
        <FeaturesList data={PARTNER_FEATURES} />
        <div className="bg-blue-100 py-16 px-4 lg:p-16">
          <div className="container flex flex-col items-center">
            <div className="text-4xl text-center leading-tight lg:leading-snug lg:max-w-5xl text-blue-900">
              <b>BOCS</b> is designed to meet the needs of construction
              professionals in various industries
            </div>
            <div className="flex flex-wrap justify-around mt-4 lg:mt-10">
              {TARGET_CUSTOMERS.map(item => {
                const { name, desc, icon: Icon } = item;
                return (
                  <div
                    className="flex flex-col lg:flex-row pt-8 px-4 lg:p-8 items-center"
                    key={name}
                  >
                    <Icon />
                    <div className="lg:max-w-xs ml-4 text-xl text-center lg:text-left text-blue-800 pt-2 lg:pt-0">
                      <b>{name}</b> {desc}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className="relative flex flex-col bg-no-repeat"
          style={{
            backgroundImage: `url("${partnerBgBottom}")`,
            backgroundSize: 'cover',
          }}
        >
          <div className="container flex flex-col items-center py-16 px-4 lg:px-0">
            <div className="text-teal-400 text-4xl text-center lg:text-left lg:text-6xl font-bold">
              Why partner with BOCS?
            </div>
            <div className="text-blue-100 max-w-3xl text-center leading-relaxed pt-2">
              <b>Drive innovation</b> and <b>expand your business</b> by
              providing your client the additional value of real-time
              construction monitoring. Partner with BOCS and get empowered with
              the BOCS tools and resources you need to differentiate your
              business. So whether you sell or provide value-added services for
              BOCS technology, you can win more customers and grow your revenue
              with us.
            </div>
            <div className="w-full flex flex-wrap flex-col lg:flex-row justify-between pt-8 lg:py-20">
              {VALUE_ADDITION.map(item => {
                const { title, icon: Icon } = item;
                return (
                  <div
                    className="flex flex-col items-center pt-8 lg:pt-0"
                    key={title}
                  >
                    <Icon />
                    <div className="text-teal-400 max-w-xs text-center pt-3">
                      {title}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="mt-16">
              <a
                className="px-4 py-2 rounded  bg-teal-400 text-center mr-8"
                href="#top"
              >
                <span className="text-xs text-center capitalize font-bold mr-4 ml-2 text-blue-900">
                  Partner with Us
                </span>
              </a>
              <a
                href="https://beta.bocs.work"
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 rounded border border-teal-400 text-teal-400 text-sm hover:bg-teal-400 hover:text-white"
              >
                View Demo
              </a>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Partners;
