import React, { useCallback } from 'react';
import { useToasts } from 'react-toast-notifications';
import LeadGenerationForm from '../LeadGenerationForm';

const PartnerLeadGenerationForm: React.FC = () => {
  const { addToast } = useToasts();

  const handleSuccess = useCallback(() => {
    addToast('Our team will contact you soon', {
      appearance: 'success',
      autoDismiss: true,
    });
    // window.open('https://demo.bocs.work?leadInfoCaptured', '_blank');
  }, [addToast]);

  return (
    <div className="bg-white px-4 py-6 rounded">
      <div className="text-2xl lg:text-3xl font-bold text-gray-700">
        Become a BOCS Partner
      </div>
      <div className="text-xs text-gray-500 mb-6">
        Please tell us a bit about yourself by filling out the form
      </div>
      <LeadGenerationForm
        type="partner"
        key="partnerLeadForm"
        onSuccess={handleSuccess}
        buttonLabel="Partner with us"
      />
    </div>
  );
};

export default PartnerLeadGenerationForm;
